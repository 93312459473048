import { useEffect, useState } from 'react';
import { breakpoints } from '@utils/constants';
import { Breakpoint } from '@typings/custom';

interface WindowSize {
  width: number;
  height: number;
  breakpoint?: Breakpoint;
  isMobile?: boolean;
  isLessThanTablet?: boolean;
  isLessThanDesktop?: boolean;
  isLessThanXL?: boolean;
  isDesktop?: boolean;
}

const getBreakpoint = (windowWidth: number) => {
  if (windowWidth > breakpoints.xl) {
    return Breakpoint.xl;
  }
  if (windowWidth > breakpoints.lg) {
    return Breakpoint.lg;
  }
  if (windowWidth > breakpoints.md) {
    return Breakpoint.md;
  }
  if (windowWidth > breakpoints.sm) {
    return Breakpoint.sm;
  }

  return Breakpoint.xs;
};

function useWindowSize(): WindowSize {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
    breakpoint: Breakpoint.lg,
    isMobile: false,
    isLessThanTablet: false,
    isLessThanDesktop: false,
    isLessThanXL: false,
    isDesktop: false,
  });

  useEffect(() => {
    const handler = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        breakpoint: getBreakpoint(window.innerWidth),
        isMobile: window.innerWidth < breakpoints.md,
        isLessThanTablet: window.innerWidth <= breakpoints.md,
        isLessThanDesktop: window.innerWidth <= breakpoints.lg,
        isLessThanXL: window.innerWidth <= breakpoints.xl,
        isDesktop: window.innerWidth > breakpoints.lg,
      });
    };

    // Set size at the first client-side load
    handler();

    window.addEventListener('resize', handler);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return windowSize;
}

export default useWindowSize;
