import * as React from 'react';
import { Link, graphql } from 'gatsby';
import Button from '@components/Button';
import Logo from '@components/Logo';
import useWindowSize from '@utils/hooks/useWindowSize';
import {
  FooterFragmentFragment,
  PrismicFooterDataColumn1Links,
  PrismicFooterDataColumn2Links,
  PrismicFooterDataColumn3Links,
} from '@typings/prismic-types';
import * as styles from './styles.module.scss';
import clsx from 'clsx';

interface Props {
  footer: FooterFragmentFragment;
}

interface ColumnProps {
  heading: string;
  columnNumber: number;
  links:
    | Array<PrismicFooterDataColumn1Links>
    | Array<PrismicFooterDataColumn2Links>
    | Array<PrismicFooterDataColumn3Links>;
}

function Column({ heading, columnNumber, links = [] }: ColumnProps) {
  return (
    <div>
      <p
        id={`footer-col-${columnNumber}`}
        className={styles.columnHeading}
        role={`heading`}
        aria-level={4}
      >
        <b>{heading}</b>
      </p>
      <ul aria-labelledby={`footer-col-${columnNumber}`}>
        {links.map((item, index) => (
          <li
            className={styles.columnLink}
            key={`footer-col${columnNumber}-${index}`}
          >
            <a className={styles.link} href={item.link.url}>
              {item.link_label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

const Copyright = () => (
  <small className={styles.copyright}>© Pry Financials Inc.</small>
);

const Footer = ({ footer }: Props) => {
  const footerData = footer.data;
  const windowSize = useWindowSize();

  return (
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className={styles.footerTop}>
          <div className={styles.heading}>
            <h4 className="marketing-xlarge-p">
              {footerData.heading.text}
              {windowSize.isLessThanTablet ? <br /> : ' '}
              <span className={styles.subheading}>
                {footerData.subheading.text}
              </span>
            </h4>
          </div>
          <div>
            <Button
              href={footerData.cta_button_link.url}
              useSearchParams
              external
              type={'Primary'}
              iconType={'Arrow'}
            >
              {footerData.cta_button_label}
            </Button>
          </div>
        </div>
        <div className={styles.footerBottom}>
          <div className={styles.logo}>
            <Link aria-label={`Homepage`} to="/">
              <Logo color={'white'} />
            </Link>
            {!windowSize.isLessThanTablet && <Copyright />}
          </div>
          <div className={styles.columns}>
            <Column
              columnNumber={1}
              heading={footerData.column1_heading.text}
              links={footerData.column1_links}
            />
            <Column
              columnNumber={2}
              heading={footerData.column2_heading.text}
              links={footerData.column2_links}
            />
            <Column
              columnNumber={3}
              heading={footerData.column3_heading.text}
              links={footerData.column3_links}
            />
          </div>
        </div>
        {windowSize.isLessThanTablet && <Copyright />}
      </div>
    </footer>
  );
};

export const query = graphql`
  fragment FooterFragment on PrismicFooter {
    type
    data {
      heading {
        html
        richText
        text
      }
      subheading {
        html
        richText
        text
      }
      cta_button_label
      cta_button_link {
        id
        url
      }
      column1_heading {
        html
        richText
        text
      }
      column1_links {
        link_label
        link {
          id
          url
        }
      }
      column2_heading {
        html
        richText
        text
      }
      column2_links {
        link_label
        link {
          id
          url
        }
      }
      column3_heading {
        html
        richText
        text
      }
      column3_links {
        link_label
        link {
          id
          url
        }
      }
    }
  }
`;

export default Footer;
