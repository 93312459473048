import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import ArrowRightIcon from '@icons/arrow-right.svg';
import ExternalLinkIcon from '@icons/external-link.svg';
import './styles.scss';

interface Props {
  children?: React.ReactNode;
  external?: boolean;
  href?: string;
  icon?: JSX.Element;
  iconType?: 'Arrow' | 'External';
  hasMarginRight?: boolean;
  ariaLabel?: string;
}

const Link: React.FC<Props> = ({
  children,
  external,
  href,
  icon,
  iconType,
  hasMarginRight,
  ariaLabel,
}: Props) => {
  if (href?.startsWith('http')) {
    external = true;
  }
  let linkClass = `link`;
  if (hasMarginRight) {
    linkClass = `${linkClass} linkMarginRight`;
  }
  let CustomIcon = null;
  if (icon || iconType) {
    linkClass += ` linkIcon`;
  }
  if (icon) {
    CustomIcon = () => icon;
  }
  if (iconType === 'Arrow') {
    CustomIcon = ArrowRightIcon;
    linkClass += ` linkIconArrow`;
  }
  if (iconType === 'External') {
    CustomIcon = ExternalLinkIcon;
  }

  if (href && external) {
    return (
      <a
        className={linkClass}
        href={href}
        target={`_blank`}
        rel={`noopener`}
        aria-label={ariaLabel}
      >
        {children}
        {CustomIcon && <CustomIcon />}
      </a>
    );
  }

  if (href) {
    return (
      <GatsbyLink className={linkClass} to={href}>
        {children}
        {CustomIcon && <CustomIcon />}
      </GatsbyLink>
    );
  }

  return null;
};

export default Link;
