import React from 'react';
import { CDN } from '@utils/constants';
import useScript from '@utils/hooks/useScript';

interface Props {
  videoUrl: string;
  children: React.ReactNode;
}

const WistiaPopoverLinkWrapper = ({ videoUrl, children }: Props) => {
  // Load wistia video and library
  const status1 = useScript(videoUrl);
  const status2 = useScript(CDN.wistia);

  if (!videoUrl) {
    return null;
  }

  const wistiaId = videoUrl.split('medias/')[1]?.split('.jsonp')[0];

  // This transparent image is necessary since otherwise Wistia will load a thumbnail when not needed.
  const transparentImage = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=`;

  return (
    <div
      className={`wistia_embed wistia_async_${wistiaId}
        popover=true popoverContent=link stillUrl=${transparentImage}`}
      style={{ display: `inline-block`, position: `relative` }}
    >
      {children}
    </div>
  );
};

export default WistiaPopoverLinkWrapper;
