export enum Breakpoint {
  xs,
  sm,
  md,
  lg,
  xl,
}

export type DocIdMap = {
  [key: string]: {
    title: string;
    url: string;
  };
};
