export const breakpoints = {
  sm: 340,
  md: 768,
  lg: 1024,
  xl: 1440,
};

export const CDN = {
  wistia: 'https://fast.wistia.net/assets/external/E-v1.js',
};
