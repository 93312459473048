import React from 'react';
import { Link } from 'gatsby';
import WistiaPopoverLinkWrapper from '@components/WistiaPopoverLinkWrapper';
import ArrowRightIcon from '@icons/arrow-right.svg';
import ExternalLinkIcon from '@icons/external-link.svg';
import PlayVideoIcon from '@icons/play-video.svg';
import './styles.scss';
import { addSearchParamsToHref } from '@utils/urls';

interface Props {
  className?: string;
  children?: React.ReactNode;
  external?: boolean;
  href?: string;
  icon?: JSX.Element;
  iconPosition?: 'Right' | 'Left';
  hasMarginRight?: boolean;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  type?: 'Primary' | 'Secondary' | 'Minimal' | 'Icon';
  size?: 'Medium' | 'Small';
  iconType?: 'Arrow' | 'External' | 'Video' | string;
  textAlign?: 'Center' | 'Left' | 'Right';
  wistiaUrl?: string;
  ariaLabel?: string;
  useSearchParams?: boolean;
}

const Button: React.FC<Props> = ({
  className,
  children,
  external,
  href,
  icon,
  iconPosition,
  hasMarginRight,
  onClick,
  onMouseOver,
  onMouseLeave,
  type = 'Primary',
  size = 'Medium',
  textAlign = 'Center',
  iconType,
  wistiaUrl,
  ariaLabel,
  useSearchParams = false,
}: Props) => {
  let buttonClass = `button button${type} button${size} button${textAlign} ${
    className || ``
  }`;
  if (href?.startsWith('http')) {
    external = true;
  }
  if (hasMarginRight) {
    buttonClass += ` buttonMarginRight`;
  }
  if (iconPosition) {
    buttonClass += ` buttonIconPosition${iconPosition}`;
  }
  let CustomIcon: any | null = null;
  if (icon) {
    CustomIcon = () => icon;
  }
  if (iconType === 'Arrow') {
    CustomIcon = ArrowRightIcon;
    buttonClass += ` buttonIconArrow`;
  }
  if (iconType === 'External') {
    CustomIcon = ExternalLinkIcon;
  }
  if (iconType === 'Video') {
    CustomIcon = PlayVideoIcon;
  }

  const Content = () => (
    <>
      {iconPosition === 'Left' && CustomIcon && <CustomIcon />}
      {children}
      {iconPosition !== 'Left' && CustomIcon && <CustomIcon />}
    </>
  );

  // Opens popover if wistiaUrl exists or url has jsonp in it
  if (
    wistiaUrl ||
    (href && href.includes('wistia') && href.includes('.jsonp'))
  ) {
    return (
      <WistiaPopoverLinkWrapper videoUrl={wistiaUrl || href}>
        <button type={'button'} className={buttonClass} aria-label={ariaLabel}>
          <Content />
        </button>
      </WistiaPopoverLinkWrapper>
    );
  }

  if (href && external) {
    const linkHref = useSearchParams ? addSearchParamsToHref(href) : href;

    return (
      <a
        className={buttonClass}
        href={linkHref}
        target={`_blank`}
        rel={`noopener`}
        aria-label={ariaLabel}
      >
        <Content />
      </a>
    );
  }

  if (href) {
    return (
      <Link className={buttonClass} to={href} aria-label={ariaLabel}>
        <Content />
      </Link>
    );
  }

  if (onClick || onMouseOver || onMouseLeave) {
    return (
      <button
        type={'button'}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onFocus={onMouseOver}
        onMouseLeave={onMouseLeave}
        className={buttonClass}
        aria-label={ariaLabel}
      >
        <Content />
      </button>
    );
  }

  return null;
};

export default Button;
