import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Footer from '@components/Footer';
import Nav from '@components/Nav';
import { PrismicFooter, PrismicNav } from '@utils/typings/prismic-types';

interface Props extends PageProps {
  data: {
    prismicNav: PrismicNav;
    prismicFooter: PrismicFooter;
  };
}

export default function TermsOfService({ data }: Props) {
  if (!data) return null;

  const nav = data.prismicNav;
  const footer = data.prismicFooter;

  return (
    <>
      <Nav nav={nav} />

      <div className="content-narrow">
        <h1 className="main-heading">Terms of Service</h1>
        <h6 className="section-heading">
          Effective Date: June 16th, 2020
          <br />
        </h6>
        <p>
          Please read these terms of service (“agreement” or “terms of use”)
          carefully before using the services offered by pry financials, inc.
          (“company”). This agreement sets forth the legally binding terms and
          conditions for your and your business or other legal entity’s (“you”)
          use of the various websites owned and operated by company, including,
          without limitation, the{' '}
          <a href="https://pry.co/" className="link">
            https://pry.co
          </a>{' '}
          website and domain name (“sites”), and any other features, content, or
          applications offered from time to time in connection therewith
          (collectively, the “service”). By using the sites or service in any
          manner, including but not limited to visiting or browsing the sites,
          you agree to be bound by this agreement. This agreement applies to all
          users of the sites or service, including users who are also
          contributors of content, information, and other materials or services
          on the sites. By entering into this agreement on behalf of a company
          or other legal entity, you represent that you have the authority to
          bind such entity to this agreement and that you are of legal age to
          form a binding contract.
        </p>
        <h2 className="section-heading">Acceptance of Terms</h2>
        <p>
          The Service is offered subject to acceptance without modification of
          these Terms of Use and all other operating rules, policies and
          procedures that may be published from time to time on the Sites by
          Company. In addition, some services offered through the Service may be
          subject to additional terms and conditions promulgated by Company from
          time to time; your use of such services is subject to those additional
          terms and conditions, which are incorporated into these Terms of Use
          by this reference.
          <br />
          <br />
          You represent and warrant that all registration information you submit
          is accurate and truthful; and that your use of the Service does not
          violate any applicable law or regulation. Company may, in its sole
          discretion, refuse to offer the Service to any entity and change its
          eligibility criteria at any time. This provision is void where
          prohibited by law and the right to access the Service is revoked in
          such jurisdictions.
        </p>
        <h2 className="section-heading">
          Modification of Terms of Use
          <br />
        </h2>
        <p>
          Company reserves the right, at its sole discretion, to modify or
          replace any of the Terms of Use, or change, suspend, or discontinue
          the Service (including without limitation, the availability of any
          feature, database, or content) at any time by posting a notice on the
          Sites or by sending you an email. Company may also impose limits on
          certain features and services or restrict your access to parts or all
          of the Service without notice or liability. It is your responsibility
          to check the Terms of Use periodically for changes. Your continued use
          of the Service following the posting of any changes to the Terms of
          Use constitutes acceptance of those changes.
          <br />
        </p>
        <h2 className="section-heading">Privacy</h2>
        <p>
          Company&apos;s current privacy policy is located at{' '}
          <a href="https://www.brex.com/legal/privacy" className="link">
            https://www.brex.com/legal/privacy
          </a>{' '}
          (the “Privacy Policy”) and is incorporated into these Terms of Use by
          this reference. For inquiries in regard to the Privacy Policy, or to
          report a privacy-related problem, please contact{' '}
          <a
            href="mailto:privacy@brex.com?subject=Privacy%20Policy%20Inquiry"
            className="link"
          >
            privacy@brex.com
          </a>
          .<br />
        </p>
        <h2 className="section-heading">Registration</h2>
        <p>
          As a condition to using certain aspects of the Service, you will be
          required to register with Company and select a password and email
          address. You shall provide Company with accurate, complete, and
          updated registration information. Failure to do so shall constitute a
          breach of the Terms of Use, which may result in immediate termination
          of your Company account. You shall not select or use an email address
          of another person with the intent to impersonate that person. Company
          reserves the right to refuse registration of, or cancel a Company
          account in its sole discretion. You are solely responsible for
          activity that occurs on your account and shall be responsible for
          maintaining the confidentiality of your Company password. You shall
          never use another user’s account without such other user’s express
          permission. You will immediately notify Company in writing of any
          unauthorized use of your account, or other account related security
          breach of which you are aware.
          <br />
          <br />
          Company shall have the right to use Customer’s name in a factual
          manner for marketing or promotional purposes on Company’s website and
          in other communication with existing or potential customers. To refuse
          Company this right, Customer must email Company (at the email address
          provided in the Service) stating that Customer does not wish to grant
          Company this right. If any provision of this Agreement is found to be
          unenforceable or invalid, that provision will be limited or eliminated
          to the minimum extent necessary so that this Agreement will otherwise
          remain in effect and enforceable.
          <br />
        </p>
        <h2 className="section-heading">Services</h2>
        <p>
          Customer shall have the right to grant users, including its employees,
          investors, vendors, advisors and agents, access to its account for use
          of the Services in accordance with this Agreement (“Authorized Users”)
          and designate which Authorized Users shall have administrative
          privileges. Customer is solely responsible for ensuring Authorized
          Users comply with the Agreement. Customer shall be responsible for all
          activities occurring under Customer’s account, including all
          activities of its Authorized Users, and for obtaining and maintaining
          any equipment and ancillary services needed to connect to, access or
          otherwise use the Services, including, without limitation, modems,
          hardware, servers, software, operating systems, networking, web
          servers and the like (collectively, “Equipment”). Customer shall also
          be responsible for maintaining the security of the Equipment, Customer
          account, passwords (including but not limited to administrative and
          Authorized User passwords) and files, and for all uses of Customer
          account or the Equipment with or without Customer’s knowledge or
          consent.
          <br />
          <br />
          Company may invite Customer to try certain services at no charge for a
          free trial or assessment or if such services are not widely available
          to customers (collectively, “Evaluation Services”). Evaluation
          Services will be identified as alpha, beta, trial, early access,
          limited release, pilot, evaluation, or similar. Evaluation Services
          are for Company’s internal analyitical purposes only and not for
          production use, are not considered “Services” under this Agreement,
          are not supported, are provided “as is” without warranty of any kind,
          and may be subject to additional terms. Company may discontinue
          Evaluation Services at any time in its sole discretion and may never
          make them generally available. Company will have no liability for any
          harm or damage arising out of or in connection with any Evaluation
          Services.
        </p>
        <h2 className="section-heading">
          Consent to Receive Electronic Communications from Company
        </h2>
        <p>
          By registering for the Service and providing your name, email, postal
          or residential address, and/or phone number through the Service, you
          expressly consent to receive electronic and other communications from
          Company, over the short term and periodically, including email
          communications. These communications will be about the Service, new
          product offers, promotions, and other matters. You may opt out of
          receiving electronic communications at any time by following the
          unsubscribe instructions contained in each communication, or by
          sending an email to{' '}
          <a
            href="mailto:support@pry.co?subject=E-comm%20Consent%20Unsubscribe"
            className="link"
          >
            support@pry.co
          </a>
          . You agree that these electronic communications satisfy any legal
          requirements that communications or notices to you be in writing.
        </p>
        <h2 className="section-heading">
          Account Information from Third Party Sites
          <br />
        </h2>
        <p>
          Through the service, you may direct company to retrieve certain
          information maintained online by third-party financial institutions or
          providers with which you have a customer relationship, maintain
          accounts or engage in financial transactions (“account information”).
          You agree to provide your username, password, pin and other log-in
          information and credentials necessary to access your account with such
          institutions or providers (“access information”), and you hereby grant
          company permission to use the access information and account
          information for the purposes contemplated by this agreement. By using
          the service, you expressly authorize company to access, store and use
          your account information maintained by identified third parties, on
          your behalf as your agent. You hereby authorize company to store and
          use your access information to accomplish the foregoing and to
          configure the service so that it is compatible with the third party
          sites for which you submit account information. This may include,
          without limitation, monitoring your usage (including the location of
          relevant clicks and links) of such third party sites (when accessed
          through the service) solely to facilitate such compatibility and our
          contemplated access to your relevant account information in connection
          with the service. For purposes of this agreement, you grant company a
          limited power of attorney, and appoint company as your
          attorney-in-fact and agent, to access third party sites using access
          information, and to retrieve, store and use your account information
          with the full power and authority to do and perform each thing
          necessary in connection with such activities, as you could do in
          person solely in connection with company’s provision of the service.
          You acknowledge and agree that when company accesses and retrieves
          account information from third party sites, company is acting as your
          agent, and not as the agent of or on behalf of the third party. You
          acknowledge and agree that the foregoing does not imply sponsorship or
          endorsement by any third party services accessible through the
          service. You represent and warrant that neither the foregoing (or
          anything else in this agreement) nor your use of the services will
          violate any agreement or terms to which you are subject, including
          without limitation, those with respect to any third party site or
          service.
          <br />
        </p>
        <h2 className="section-heading">
          Financial Disclaimer
          <br />
        </h2>
        <p>
          The service is meant as an aid to assist you in organizing and
          managing your finances. It is not intended to provide legal, tax or
          financial advice. You acknowledge and agree that you are responsible
          for your own legal, tax, financial and investment research and
          decisions, that the service is only one tool that you may use as part
          of a comprehensive analysis process that should involve many other
          tools and sources of information, that you should not rely on the
          service, and that company will not be liable for any decision made or
          action taken by you or others based upon information or materials
          obtained through use of the service. Prior to the implementation of
          any legal, tax, financial or investment decision or activity, you
          should always consult with your relevant legal, tax, financial or
          investment advisor or representative. The service is not intended to
          provide legal, tax or financial advice. Company is not a financial
          planner, broker or tax advisor.
          <br />
        </p>
        <h2 className="section-heading">
          Fees and Payment
          <br />
        </h2>
        <p>
          Company reserves the right to require payment of fees for certain
          features of the Service. Should you elect to subscribe to such
          features, you shall pay all applicable fees, as described on the Sites
          in connection with such features. Company reserves the right to change
          its pricing and to institute new charges at any time, upon ten (10)
          days prior notice to you, which may be sent by email or posted on the
          Sites. Use of the Service by you following such notification
          constitutes your acceptance of any new or increased charges.
          <br />
        </p>
        <h2 className="section-heading">
          Payment Processing Services
          <br />
        </h2>
        <p>
          Payment processing services for you on Company are provided by Stripe
          and are subject to the Stripe Connected Account Agreement, which
          includes the Stripe Terms of Service (collectively, the “Stripe
          Services Agreement”). By agreeing to these terms or continuing to
          operate as a user on Company, you agree to be bound by the Stripe
          Services Agreement, as the same may be modified by Stripe from time to
          time. As a condition of Company enabling payment processing services
          through Stripe, you agree to provide Company accurate and complete
          information about you and your business, and you authorize Company to
          share it and transaction information related to your use of the
          payment processing services provided by Stripe.
          <br />
        </p>
        <h2 className="section-heading">
          Online Alerts
          <br />
        </h2>
        <p>
          Through the Service, you may receive certain types of alerts. Account
          alerts may be turned on by default as part of the Service. They may
          then be customized, deactivated or reactivated by you. These alerts
          allow you to choose alert messages for your accounts. Company may add
          new alerts from time to time, or cease to provide certain alerts at
          any time in its sole discretion. Each alert has different options
          available, and you may be asked to select from among these options.
          <br />
          <br />
          Electronic alerts will be sent to the email address you have provided
          as your primary email address. You are responsible for informing us of
          changes to your e-mail address. Because alerts are not encrypted, we
          will never include your password. However, alerts may include
          information about your accounts. Depending upon which alerts you
          select, information such as an account balance or the due date for
          your credit card payment may be included. Anyone with access to your
          email will be able to view the content of these alerts. You may
          disable future alerts at any time.
          <br />
          <br />
          You understand and agree that any alerts provided to you through the
          Service may be delayed or prevented by a variety of factors. Company
          does its best to provide alerts in a timely manner with accurate
          information. However, we neither guarantee the delivery nor the
          accuracy of any alert. You also agree that Company shall not be liable
          for any delays in delivery or failure to deliver any alert; for any
          errors in the content of an alert; or for any actions taken or not
          taken by you or any third party in reliance on an alert.
        </p>
        <h2 className="section-heading">
          Third Party Sites
          <br />
        </h2>
        <p>
          The Service may permit you to link to other websites or resources on
          the Internet, and other websites or resources may contain links to the
          Sites. When you access third party websites, you do so at your own
          risk. These other websites are not under Company&apos;s control, and
          you acknowledge that Company is not responsible or liable for the
          content, functions, accuracy, legality, appropriateness or any other
          aspect of such websites or resources. The inclusion of any such link
          does not imply endorsement by Company or any association with its
          operators. You further acknowledge and agree that Company does not:
          (i) guarantee the accuracy, completeness, or usefulness of any
          third-party information accessible on or through the Service; or (ii)
          adopt, endorse, or accept responsibility for the accuracy or
          reliability of any opinion, advice, or statement made by a third party
          through the Service. Under no circumstances will Company be
          responsible for any loss or damage resulting from your reliance on
          information or other content, goods or services available on or
          through the Service transmitted to or by any third party.
          <br />
        </p>
        <h2 className="section-heading">
          Rules and Conduct
          <br />
        </h2>
        <p>
          You may not use the Service for any purpose that is prohibited by
          these Terms of Use. The Service is provided only for your internal use
          in connection with your lawful business activities. You are
          responsible for all of your activity in connection with the Service.
          For purposes of the Terms of Use, the term “Content” includes, without
          limitation, any advertisements, advice, suggestions, blogs or forum
          comments, information, data, text, photographs, software, scripts,
          graphics, and interactive features generated, provided, or otherwise
          made accessible by Company or its partners on or through the Service.
          <br />
          <br />
          By way of example, and not as a limitation, you shall not (and shall
          not permit any third party to) either (a) take any action or (b) post
          any content on or through the Service, that:
          <br />
        </p>
        <ul className="list">
          <li className="list-item p_small">
            infringes any intellectual property or other proprietary right of
            any other person or entity;
          </li>
          <li className="list-item p_small">
            is unlawful, threatening, abusive, harassing, defamatory, libelous,
            deceptive, fraudulent, invasive of another&apos;s privacy, tortious,
            obscene, offensive, or profane;
            <br />
          </li>
          <li className="list-item p_small">
            constitutes unauthorized or unsolicited advertising, junk or bulk
            e-mail (&quot;spamming&quot;);
            <br />
          </li>
          <li className="list-item p_small">
            involves commercial activities and/or sales without Company’s prior
            written consent, such as contests, sweepstakes, barter, advertising,
            or pyramid schemes;
            <br />
          </li>
          <li className="list-item p_small">
            contains software viruses or any other computer codes, files, or
            programs that are designed or intended to disrupt, damage, limit or
            interfere with the proper function of any software, hardware, or
            telecommunications equipment or to damage or obtain unauthorized
            access to any system, data, password or other information of Company
            or any third party; or
            <br />
          </li>
          <li className="list-item p_small">
            impersonates any person or entity, including any employee or
            representative of Company.
            <br />
          </li>
        </ul>
        <p>
          Additionally, you shall not: (i) take any action that imposes or may
          impose (as determined by Company in its sole discretion) an
          unreasonable or disproportionately large load on Company’s (or its
          third party providers’) infrastructure; (ii) interfere or attempt to
          interfere with the proper working of the Service or any activities
          conducted on the Service; (iii) bypass any measures Company may use to
          prevent or restrict access to the Service (or other accounts, computer
          systems or networks connected to the Service); (iv) run Maillist,
          Listserv, any form of auto-responder or “spam” on the Service; or (v)
          use manual or automated software, devices, or other processes to
          “crawl” or “spider” any page of the Sites.
          <br />
          <br />
          You shall not (directly or indirectly): (i) decipher, decompile,
          disassemble, reverse engineer or otherwise attempt to derive any
          source code or underlying ideas or algorithms of any part of the
          Service, except to the limited extent applicable laws specifically
          prohibit such restriction, (ii) modify, translate, or otherwise create
          derivative works of any part of the Service, (iii) copy, rent, lease,
          distribute, or otherwise transfer any or all of the rights that you
          receive hereunder, or (iv) use or access the Service in order to build
          a competitive product or service. You shall abide by all applicable
          local, state, national and international laws and regulations when
          using the Service.
          <br />
          <br />
          Company reserves the right to remove any Content from the Sites or
          Service at any time, for any reason (including, but not limited to,
          upon receipt of claims or allegations from third parties or
          authorities relating to such Content or if Company is concerned that
          you may have violated the Terms of Use), or for no reason at all.
          <br />
        </p>
        <h2 className="section-heading">
          Company and Site Content
          <br />
        </h2>
        <p>
          You agree that the Service contains Content specifically provided by
          Company or its partners and that such Content is protected by
          copyrights, trademarks, service marks, patents, trade secrets or other
          proprietary rights and laws. You shall abide by all copyright notices,
          information, and restrictions contained in any Content accessed
          through the Service. You shall not sell, license, rent, modify,
          distribute, copy, reproduce, transmit, publicly display, publicly
          perform, publish, adapt, edit, create derivative works from, or
          otherwise exploit any Content or third party submissions or other
          proprietary rights not owned by you (i) without the consent of the
          respective owners or other valid right, and (ii) in any way that
          violates any third party right.
          <br />
          <br />
          You may, to the extent the Sites expressly authorize you to do so,
          download or copy the Content, and other items displayed on the Sites
          for download, for your internal business use only, provided that you
          maintain all copyright and other notices contained in such Content.
          You shall not store any significant portion of any Content in any
          form. Copying or storing of any Content for other than for your
          internal business use is expressly prohibited without prior written
          permission from Company, or from the copyright holder identified in
          such Content&apos;s copyright notice.
          <br />
        </p>
        <h2 className="section-heading">
          User Submissions
          <br />
        </h2>
        <p>
          The Service may also provide you with the ability to post content such
          as blog comments to the Service. Although some of the individuals
          posting to the Site work for Company, any opinions expressed are the
          personal opinions of the original authors, not of Company. The content
          is provided for informational and entertainment purposes only and is
          not meant to be an endorsement or representation by Company or any
          other party. Company assumes no responsibility or liability for any
          blogs, opinions or other commentary posted on the Site or any website
          linked to the Site, and makes no express or implied warranty or
          guarantee about the accuracy, copyright compliance, legality, or any
          other aspect of such content.
          <br />
          <br />
          The Site is available to the public. No information you consider
          confidential should be posted to this Site. If you submit content, you
          agree that Company may reveal your identity and whatever information
          we know about you to any law enforcement agent or official in the
          event of legal action arising from any posting(s) made by you. Company
          may modify, display, delete, transmit or distribute content posted on
          the Site in its sole discretion and without your permission. However,
          Company shall not be responsible for controlling or editing any
          content, nor can Company ensure prompt removal of inappropriate or
          unlawful content. You also grant to Company a worldwide, perpetual,
          irrevocable, royalty-free and fully-paid, transferable (including
          rights to sublicense) right to fully exercise and exploit all
          intellectual property, publicity, and moral rights with respect to any
          content you provide.
          <br />
          <br />
          By posting you agree to be solely responsible for the content of all
          information you contribute, link to, or otherwise upload to the Site,
          and release Company from any liability related to your use of the
          Site. Company reserves the right to remove any content from the Site
          at any time, for any reason (including, but not limited to, upon
          receipt of claims or allegations from third parties or authorities
          relating to such content or if Company is concerned that you may have
          breached the immediately preceding sentence), or for no reason at all.
          <br />
        </p>
        <h2 className="section-heading">
          Confidentiality and Feedback
          <br />
        </h2>
        <p>
          Neither Company or Customer will use or disclose the other party’s
          Confidential Information without the other’s prior written consent,
          except for the purpose of exercising rights under or performing this
          Agreement, or if required by law, regulation or court order; in which
          case, the party being compelled to disclose Confidential Information
          will give the other party as much notice as is reasonably practicable
          before disclosing the Confidential Information.
          <br />‍<br />
          When Customer provide Company with any suggestions, information,
          ideas, or feedback concerning Pry, its functionality and features, or
          any model, report or output, errors discovered, or any suggestions for
          or relating to any models or output (“Feedback”) while using Pry, such
          Feedback will be the property of Company. You agree to assign, and
          hereby assign, all right, title and interest worldwide in the
          Feedback, and the related intellectual property rights, to Company and
          agree to assist Company in perfecting and enforcing these rights.
          <br />
        </p>
        <h2 className="section-heading">
          License
          <br />
        </h2>
        <p>
          Subject to your compliance with these Terms of Use, Company grants you
          a non-exclusive, non-sublicensable, revocable as stated in this
          Agreement, non-transferable license to access the Company websites
          (located at the following URL:{' '}
          <a
            href="https://pry.co/"
            target="_blank"
            className="link"
            rel="noreferrer"
          >
            https://pry.co
          </a>
          ), and to use the Service. No part of the Service, including the
          Website, may be reproduced, duplicated, copied, modified, sold,
          resold, distributed, transmitted, or otherwise exploited for any
          commercial purpose without the prior express written consent of
          Company. All rights not expressly granted in this Agreement are
          reserved by Company. Without limitation, this Agreement grants you no
          rights to the intellectual property of Company or any other party,
          except as expressly stated in this Agreement. The license granted in
          this section is conditioned on your compliance with this Agreement.
          Your rights under this section will immediately terminate if, in the
          sole judgment of Company, you have breached any provision of this
          Agreement.
          <br />
        </p>
        <h2 className="section-heading">
          Termination
          <br />
        </h2>
        <p>
          Company may terminate your access to all or any part of the Service at
          any time, with or without cause, with or without notice, effective
          immediately, which may result in the forfeiture and destruction of all
          information associated with your membership. If you wish to terminate
          your account, you may do so by following the instructions on the
          Sites. Any fees paid hereunder are non-refundable. All provisions of
          the Terms of Use which by their nature shall survive termination,
          including, without limitation, ownership provisions, warranty
          disclaimers, indemnity and limitations of liability.
          <br />
          <br />
          If Company, in Company’s discretion, takes legal action against you in
          connection with any actual or suspected breach of this Agreement,
          Company will be entitled to recover from you as part of such legal
          action, and you agree to pay, Company’s reasonable costs and
          attorneys’ fees incurred as a result of such legal action. The Company
          Parties will have no legal obligation or other liability to you or to
          any third party arising out of or relating to any termination of this
          Agreement.
          <br />
        </p>
        <h2 className="section-heading">
          Warranty Disclaimer
          <br />
        </h2>
        <p>
          Company makes no representations concerning any content contained in
          or accessed through the sites, and company will not be responsible or
          liable for the reliability, timeliness, quality, suitability,
          availability, accuracy, completeness, copyright compliance, legality
          or decency of any content or material contained in or accessed through
          the sites. You should independently verify all content and other
          information that you access through the service. By using the service,
          you agree that company shall not be responsible for (1) any content,
          (2) any person’s reliance on any such content, whether or not correct,
          current and complete, or (3) the consequences of any action that you
          or any other person takes or fails to take based on any content or
          otherwise as a result of your use of the service. Your use of or
          reliance on any content is at your own risk.
          <br />
          <br />
          The service (including, without limitation, any content) is provided
          &quot;As is&quot; and &quot;As available&quot; and is without warranty
          of any kind, express or implied, including, but not limited to, the
          implied warranties of title, non-infringement, merchantability and
          fitness for a particular purpose, and any warranties implied by any
          course of performance or usage of trade, all of which are expressly
          disclaimed. Company, and its directors, employees, agents, suppliers,
          partners and content providers do not warrant that: (a) the service
          will be secure or available at any particular time or location; (b)
          the use of the service will be secure, timely, uninterrupted or
          error-free, or operate in combination with any other hardware,
          software, system or data; (b) any defects or errors will be corrected;
          (c) any content or software available at or through the service is
          free of viruses or other harmful components; or (d) the results of
          using the service will meet your requirements or expectations. Your
          use of the service is solely at your own risk. The services may be
          subject to limitations, delays, and other problems inherent in the use
          of the internet and electronic communications, and company is not
          responsible for any delays, delivery failures, or other damage
          resulting from such problems.
          <br />
          <br />
          Some states do not allow limitations on how long an implied warranty
          lasts, so the above limitations may not apply to you. Electronic
          communications privacy act notice (18 usc 2701-2711): company makes no
          guaranty of confidentiality or privacy of any communication or
          information transmitted on the sites or any website linked to the
          sites.
          <br />
          <br />
          Company will not be liable for the privacy of email addresses,
          registration and identification information, disk space,
          communications, confidential or trade-secret information, or any other
          content stored on company’s equipment, transmitted over networks
          accessed by the sites, or otherwise connected with your use of the
          service.
          <br />
        </p>
        <h2 className="section-heading">
          Indemnification
          <br />
        </h2>
        <p>
          You shall defend, indemnify, and hold harmless Company, its affiliates
          and each of its, and its affiliates employees, contractors, directors,
          suppliers and representatives from all liabilities, claims, and
          expenses, including reasonable attorneys&apos; fees, that arise from
          or relate to (i) your use or misuse of, or access to, the Sites,
          Service, Content, or otherwise from any content that you post to the
          Sites, (ii) your violation of the Terms of Use, or (iii) infringement
          by you, or any third party using the your account, of any intellectual
          property or other right of any person or entity. Company reserves the
          right to assume the exclusive defense and control of any matter
          otherwise subject to indemnification by you, in which event you will
          assist and cooperate with Company in asserting any available defenses.
          <br />
        </p>
        <h2 className="section-heading">
          Limitation of Liability
          <br />
        </h2>
        <p>
          In no event shall company, nor its directors, employees, agents,
          partners, suppliers or content providers, be liable under contract,
          tort, strict liability, negligence or any other legal or equitable
          theory with respect to the service (including, without limitation, any
          content) (I) for any lost profits, data loss, cost of procurement of
          substitute goods or services, or special, indirect, incidental,
          punitive, or consequential damages of any kind whatsoever (however
          arising), (ii) for any bugs, viruses, trojan horses, or the like
          (regardless of the source), (iii) for your reliance on the service or
          (iv) for any direct damages in excess of (in the aggregate)
          one-hundred U.S. Dollars ($100.00) or, if greater, the fees paid by
          you for the service in the preceding six (6) month period. Some states
          do not allow the exclusion or limitation of incidental or
          consequential damages, so the above limitations and exclusions may not
          apply to you.
          <br />
        </p>
        <h2 className="section-heading">
          International/Non-California Use
          <br />
        </h2>
        <p>
          Company makes no representation that the Content is appropriate or
          available for use in locations outside of California, and accessing
          the Service is prohibited from territories where such Content is
          illegal. If you access the Service from other locations, you do so at
          your own initiative and are responsible for compliance with local
          laws.
          <br />
        </p>
        <h2 className="section-heading">
          Dispute Resolution
          <br />
        </h2>
        <p>
          A printed version of the Terms of Use and of any notice given in
          electronic form shall be admissible in judicial or administrative
          proceedings based upon or relating to the Terms of Use to the same
          extent and subject to the same conditions as other business documents
          and records originally generated and maintained in printed form. You
          and Company agree that any cause of action arising out of or related
          to the Service must commence within one (1) year after the cause of
          action arose; otherwise, such cause of action is permanently barred.
          <br />
          <br />
          The Terms of Use shall be governed by and construed in accordance with
          the laws of the State of California, excluding its conflicts of law
          rules. Any dispute arising from or relating to the subject matter of
          this Agreement shall be finally settled by arbitration in San
          Francisco County, California, using the English language in accordance
          with the Arbitration Rules and Procedures of Judicial Arbitration and
          Mediation Services, Inc. (&quot;JAMS&quot;) then in effect, by one
          commercial arbitrator with substantial experience in resolving
          intellectual property and commercial contract disputes, who shall be
          selected from the appropriate list of JAMS arbitrators in accordance
          with the Arbitration Rules and Procedures of JAMS. The prevailing
          party in the arbitration shall be entitled to receive reimbursement of
          its reasonable expenses (including reasonable attorneys&apos; fees,
          expert witness fees and all other expenses) incurred in connection
          therewith. Judgment upon the award so rendered may be entered in a
          court having jurisdiction or application may be made to such court for
          judicial acceptance of any award and an order of enforcement, as the
          case may be. Notwithstanding the foregoing, each party shall have the
          right to institute an action in a court of proper jurisdiction for
          injunctive or other equitable relief pending a final decision by the
          arbitrator. For all purposes of this Agreement, the parties consent to
          exclusive jurisdiction and venue in the United States Federal Courts
          located in the Northern District of California. Use of the Service is
          not authorized in any jurisdiction that does not give effect to all
          provisions of the Terms of Use, including without limitation, this
          section.
          <br />
        </p>
        <h2 className="section-heading">
          Integration and Severability
          <br />
        </h2>
        <p>
          The Terms of Use are the entire agreement between you and Company with
          respect to the Service and use of the Sites, and supersede all prior
          or contemporaneous communications and proposals (whether oral, written
          or electronic) between you and Company with respect to the Sites. If
          any provision of the Terms of Use is found to be unenforceable or
          invalid, that provision will be limited or eliminated to the minimum
          extent necessary so that the Terms of Use will otherwise remain in
          full force and effect and enforceable. The failure of either party to
          exercise in any respect any right provided for herein shall not be
          deemed a waiver of any further rights hereunder.
          <br />
        </p>
        <h2 className="section-heading">
          Government Restrictions
          <br />
        </h2>
        <p>
          Customer may not remove or export from the United States or allow the
          export or re-export of Pry Financials. As defined in FAR section
          2.101, the Software and documentation are “commercial items” and
          according to DFAR section 252.2277014(a)(1) and (5) are deemed to be
          “commercial computer software” and “commercial computer software
          documentation.” Consistent with DFAR section 227.7202 and FAR section
          12.212, any use modification, reproduction, release, performance,
          display, or disclosure of such commercial software or commercial
          software documentation by the U.S. Government will be governed solely
          by the terms of this Agreement and will be prohibited except to the
          extent expressly permitted by the terms of this Agreement.
          <br />
        </p>
        <h2 className="section-heading">
          General
          <br />
        </h2>
        <p>
          Company shall not be liable for any failure to perform its obligations
          hereunder where such failure results from any cause beyond Company’s
          reasonable control, including, without limitation, mechanical,
          electronic or communications failure or degradation (including
          &quot;line-noise&quot; interference). The Terms of Use are personal to
          you, and are not assignable, transferable or sublicensable by you
          except with Company&apos;s prior written consent. Company may assign,
          transfer or delegate any of its rights and obligations hereunder
          without consent. No agency, partnership, joint venture, or employment
          relationship is created as a result of the Terms of Use and neither
          party has any authority of any kind to bind the other in any respect.
          In any action or proceeding to enforce rights under the Terms of Use,
          the prevailing party will be entitled to recover costs and
          attorneys&apos; fees. All notices under the Terms of Use will be in
          writing and will be deemed to have been duly given when received, if
          personally delivered or sent by certified or registered mail, return
          receipt requested; when receipt is electronically confirmed, if
          transmitted by facsimile or e-mail; or the day after it is sent, if
          sent for next day delivery by recognized overnight delivery service.
          <br />
        </p>
        <h2 className="section-heading">
          Copyright and Trademark Notices
          <br />
        </h2>
        <p>
          Unless otherwise indicated, the Terms of Use and all Content provided
          by Company are copyright © 2020 Pry Financials, Inc. All rights
          reserved.
          <br />
        </p>
        <h2 className="section-heading">
          Notices
          <br />
        </h2>
        <p>
          All notices required or permitted to be given under this Agreement
          must be in writing. Company shall give any notice by email sent to the
          most recent email address, if any, provided by the intended recipient
          to Company. You agree that any notice received from Company
          electronically satisfies any legal requirement that such notice be in
          writing. You bear the sole responsibility of ensuring that your email
          address on file with Company is accurate and current, and notice to
          you shall be deemed effective upon the sending by Company of an email
          to that address.
          <br />
        </p>
        <h2 className="section-heading">
          Contact
          <br />
        </h2>
        <p>
          You may contact Company by email to{' '}
          <a href="mailto:support@pry.co?subject=Hi" className="link">
            support@pry.co
          </a>
          .<br />
        </p>
      </div>

      <Footer footer={footer} />
    </>
  );
}

export const query = graphql`
  query termsOfServiceQuery($lang: String) {
    prismicNav(lang: { eq: $lang }) {
      ...NavFragment
    }
    prismicFooter(lang: { eq: $lang }) {
      ...FooterFragment
    }
  }
`;
