// extracted by mini-css-extract-plugin
export var strike = "styles-module--strike--zdYKI";
export var footer = "styles-module--footer--ohZ-9";
export var footerWrapper = "styles-module--footerWrapper--MMmmK";
export var footerTop = "styles-module--footerTop--jS9lJ";
export var footerBottom = "styles-module--footerBottom--iqLMw";
export var logo = "styles-module--logo--vm1Bk";
export var copyright = "styles-module--copyright--hamqF";
export var heading = "styles-module--heading--6p-BC";
export var subheading = "styles-module--subheading---HXnY";
export var columnHeading = "styles-module--columnHeading--UIBSE";
export var columns = "styles-module--columns--wXAqi";
export var columnLink = "styles-module--columnLink--cXJ9F";
export var link = "styles-module--link--DeXoH";