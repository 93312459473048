import * as React from 'react';

interface Props {
  color?: 'black' | 'white';
}

const Logo = ({ color = 'black' }: Props) => {
  const textFillColor = color === 'white' ? '#FFF' : '#013';

  return (
    <>
      <svg
        width="79"
        height="32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.712 13.512a6.695 6.695 0 003.736-1.135 6.748 6.748 0 002.478-3.03 6.79 6.79 0 00-1.451-7.365A6.687 6.687 0 004.15.514a6.728 6.728 0 00-3.018 2.488 6.782 6.782 0 00.832 8.528 6.702 6.702 0 004.747 1.982z"
          fill="#F4C222"
        />
        <path d="M14.896 23.076v-8.092l8.05 8.092h-8.05z" fill="#5C5CE5" />
        <path
          d="M22.946 6.898L6.854 23.076H0L22.946.008v6.89z"
          fill="#E54C4C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.054.008h2.935a7.746 7.746 0 015.421 2.287 7.829 7.829 0 012.275 5.45 7.71 7.71 0 01-2.24 5.413 7.628 7.628 0 01-5.385 2.253h-4.265v7.665h-4.62V.008h4.62v10.83h4.194c.816 0 1.599-.326 2.176-.906a3.102 3.102 0 000-4.374 3.069 3.069 0 00-2.176-.906h-4.194L38.054.008zm11.748 23.068h4.62V6.622h-4.62v16.454zM64.911 6.622l4.61 16.439 4.62-16.44h4.548L71.607 32h-4.595l2.51-8.924H64.91L60.29 6.622l-1.274 4.564H54.42l1.322-4.564h9.168z"
          fill={textFillColor}
        />
      </svg>
    </>
  );
};

export default Logo;
