export function addSearchParamsToHref(href: string | undefined) {
  if (!href) return href;

  let searchParams;

  try {
    searchParams = window.location.search.slice(1);
  } catch (error) {
    console.log(error);
  }

  if (!searchParams) {
    return href;
  } else if (href.includes('?')) {
    return `${href}&${searchParams}`;
  }
  return `${href}?${searchParams}`;
}
